<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <a href="https://learntech.rwth-aachen.de/" target="_blank"><v-img
          alt="LuFGi9 Logo"
          class="shrink mr-2"
          contain
          src="./assets/images/logo.png"
        /></a>
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://dl.gi.de/handle/20.500.12116/39935" target="_blank" text>
        <span class="mr-2">Go to paper</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn href="https://gitlab.com/learntech-rwth/eduxr-framework/xr-oer-inventory" target="_blank" text>
        <span class="mr-2">Git Repository</span>
        <v-icon>mdi-git</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-card flat>
              <v-card-title>Open Educational Resources and Extended Reality: An inventory</v-card-title>
              <v-card-text>
                Virtual reality (VR) in education is a current field of research, e.g. because it provides an immersive and safe learning environment for dangerous or expensive training. But developing VR learning applications is time-consuming and costly. The development of open educational resources (OER) can facilitate educators’ access to VR and help integrate technology into everyday education. This paper presents the current state of open educational resources in virtual reality and discusses the possibilities and challenges of combining VR and OER.
              </v-card-text>
              <v-card-text>On the right you see a first approach. You can click on it to make it bigger. After considering feedback this version was identified as not well suited for this purpose. Thus, this website and the below table was created.</v-card-text>
              <v-card-text><strong>Disclaimer (01.10.2022):</strong> This is a first approach based on student seminar work. Thus, the results are not completely reflected or evaluated yet. This will be done in further studies. Thus, handle these classifications with care.</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on }">
                <div class="ma-2"></div>
                <img style="cursor: pointer; max-width: 100%" src="./assets/images/overview_DELFI22_OER-preview.png" v-on="on" alt="card based overview" />
                <v-card-text class="text-center">
                  <small>All trademarks displayed on this slide are the trademarks of their respective owners.</small>
                </v-card-text>
              </template>
              <v-card>
                <v-img @click="dialog = false" lazy-src="./assets/images/overview_DELFI22_OER-preview.png" src="./assets/images/overview_DELFI22_OER.png" />
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>

      </v-container>
      <v-container>
        <AppsTable :apps="apps"/>
      </v-container>
    </v-main>
    <v-footer class="justify-center">2022 — LuFGi9 RWTH Aachen University, Sergej Görzen, Birte Heinemann, Marlene Heim, and Ulrik Schroeder, content is licensed under CC-BY-SA</v-footer>
  </v-app>
</template>

<script>
import apps from "./assets/js/open-xr-apps";
import AppsTable from "@/components/AppsTable";

export default {
  name: "App",

  components: {
    AppsTable
  },

  data: () => ({
    dialog: false,
    apps
  }),
};
</script>

<style>
>>> .v-application .primary {
  background-color: #00549F;
}
.v-sheet.v-footer {
  background: rgba(0,0,0,0.75);
  color: white;
}
</style>
