<template>
  <div>
    <v-data-table
        caption="XR Inventory of Open Educational Applications"
        :headers="headers"
        :items="filteredItems"
        item-key="index"
        :items-per-page="50"
        class="elevation-1"
        :search="filter.search"
        :loading="filter.loading"
    >
      <template v-slot:footer>
        <v-card flat>
          <v-card-title>Found at sources</v-card-title>
          <v-card-text>
            <div v-for="({keywords, url, name, href, accessed, lastUpdate}, index) in sources" :key="index">
              <span>[{{index+1}}]&nbsp;{{name}}</span>&nbsp;
              <a v-if="url" :href="href || url" target="_blank">{{url}}</a>
              <span v-if="keywords.length > 0">, keywords: {{keywords.map(k => `'${k}'`).join(', ')}}</span>
              <span v-if="accessed">, accessed {{accessed}}</span>
              <span v-if="lastUpdate">, updated {{lastUpdate}}</span>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <strong>{{item.name}}</strong>
        <v-btn icon small v-if="item.repository" target="_blank" :href="item.repository">
          <v-icon small>mdi mdi-git</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.logo`]="{ item }">
       <a :href="item.homepage ? item.homepage : '#'" target="_blank">
         <div class="logo" :style="{backgroundImage: 'url(logos/thumbs/'+ item.logo +')'}" />
       </a>
      </template>
      <template v-slot:[`item.developers`]="{ item }">
        {{ item.developers.join(', ') }}
      </template>
      <template v-slot:[`item.featuresNames`]="{ item }">
        {{ item.featuresNames.join(', ') }}
      </template>
      <template v-slot:[`item.categoriesNames`]="{ item }">
        {{ item.categoriesNames.join(', ') }}
      </template>
      <template v-slot:[`item.levelsNames`]="{ item }">
        {{ item.levelsNames.join(', ') }}
      </template>

      <template v-slot:top>
        <v-card flat>
          <v-card-title>Filter by software features</v-card-title>
          <v-card-actions>
            <v-checkbox label="Select all" class="pr-4" v-model="filter.features.selectAllModel" @change="updateSelectAll(filter.features)" />
            <v-checkbox v-for="(feature, index) in filter.features.items" :label="feature.text" :key="index" v-model="feature.selected" class="pr-4" @change="changedSelection(filter.features, feature)" />
          </v-card-actions>
        </v-card>
        <v-card flat>
          <v-card-title>Filter by categories</v-card-title>
          <v-card-actions>
            <v-checkbox label="Select all" class="pr-4" v-model="filter.categories.selectAllModel" @change="updateSelectAll(filter.categories)" />
            <v-checkbox v-for="(category, index) in filter.categories.items" :label="category.text" v-model="category.selected" :key="index" class="pr-4" @change="changedSelection(filter.categories, category)" />
          </v-card-actions>
        </v-card>
        <v-card flat>
          <v-card-title>Filter by levels</v-card-title>
          <v-card-actions>
            <v-checkbox label="Select all" class="pr-4" v-model="filter.levels.selectAllModel" @change="updateSelectAll(filter.levels)" />
            <v-checkbox v-for="(level, index) in filter.levels.items" :label="level.text" v-model="level.selected" :key="index" class="pr-4" @change="changedSelection(filter.levels, level)" />
          </v-card-actions>
        </v-card>
        <v-card flat>
          <v-card-title>Filter by sources</v-card-title>
          <v-card-actions>
            <v-checkbox label="Select all" class="pr-4" v-model="filter.sources.selectAllModel" @change="updateSelectAll(filter.sources)" />
            <v-checkbox v-for="(source, index) in filter.sources.items" :label="source.text" v-model="source.selected" :key="index" class="pr-4" @change="changedSelection(filter.sources, source)"  />
          </v-card-actions>
        </v-card>
        <v-card flat>
          <v-card-title>Filter by freetext</v-card-title>
          <v-card-actions>
            <v-text-field
                v-model="filter.search"
                label="Search"
                class="mx-4"
            />
          </v-card-actions>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import OpenXrApp from "@/assets/js/OpenXrApp.class";
// eslint-disable-next-line no-unused-vars
import {FeatureNames, Sources, SourcesInformation, LevelNames, CategoryNames} from "@/assets/js/Enums";

export default {
  name: "AppsTable",
  components: {},
  props: {
    apps: {
      type: [OpenXrApp],
      required: true
    }
  },
  data() {
    return {
      OpenXrApp,
      filter: {
        timeout: null,
        loading: false,
        search: "",
        categories: {
          selectAllModel: true,
          items: CategoryNames.map(f => { return { text: f, selected: true } }),
        },
        features: {
          selectAllModel: true,
          items: FeatureNames.map(c => { return { text: c, selected: true } }),
        },
        levels: {
          selectAllModel: true,
          items: LevelNames.map(l => { return { text: l, selected: true }}),
        },
        sources: {
          selectAllModel: true,
          items: SourcesInformation.map(s => { return { text: s.name, selected: true }})
        }
      },
      items: this.apps,
      filteredItems: []
    };
  },
  mounted() {
    this.updateFilteredItems();
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    changedSelection(holder, _) {
      const selectedCount = holder.items.filter(i => i.selected).length;
      if (selectedCount > 0) {
        if (!holder.selectAllModel && selectedCount >= holder.items.length)
          holder.selectAllModel = true;
        this.updateFilteredItems();
        return;
      }

      holder.selectAllModel = false;

      this.updateFilteredItems();
    },
    updateSelectAll(container) {
      container.items.forEach(i => i.selected = container.selectAllModel);
      this.updateFilteredItems();
    },
    matchWithSelected(selected, target) {
      for (let i = 0; i < selected.length; i++) {
        if (target.includes(selected[i]))
          return true;
      }
      return false;
    },
    getSelectedItems(fromItems){
      return fromItems.filter(i => i.selected).map(i => i.text);
    },
    getSelectedIndices(selectedGroup) {
      const indices = [];
      for (let i = 0; i < selectedGroup.length; i++) {
        if (selectedGroup[i].selected)
          indices.push(i);
      }
      return indices;
    },
    updateFilteredItems() {
      //const selectedCategories = 0;
      this.filter.loading = true;
      this.filteredItems.splice(0, this.filteredItems.length);
      clearTimeout(this.filter.timeout);
      this.filter.timeout = setTimeout(() => {
        const selectedFeatures = this.getSelectedIndices(this.filter.features.items);
        const selectedCategories = this.getSelectedIndices(this.filter.categories.items);
        const selectedLevels = this.getSelectedIndices(this.filter.levels.items);
        const selectedSources = this.getSelectedIndices(this.filter.sources.items);

        this.items.forEach(item => {
          if (!this.matchWithSelected(selectedFeatures, item.features)) {
            return;
          }

          if (!this.matchWithSelected(selectedCategories, item.categories)) {
            return;
          }

          if (!this.matchWithSelected(selectedLevels, item.levels)) {
            return;
          }

          if (!this.matchWithSelected(selectedSources, item.sources)) {
            return;
          }

          this.filteredItems.push(item);
        });

        this.filter.loading = false;
        clearTimeout(this.filter.timeout);
      }, 10);
    },
  },
  computed: {
    sources() {
      return SourcesInformation.map(s => s);
    },
    headers () {
      return [
        {
          text: "Logo",
          sortable: false,
          align: "center",
          value: "logo"
        },
        {
          text: "XR Application Name",
          value: "name",
        },
        {
          text: "Developer/s",
          value: "developers"
        },
        {
          text: "Software features",
          value: "featuresNames"
        },
        {
          text: "Categories",
          value: "categoriesNames"
        },
        {
          text: "Educational levels",
          value: "levelsNames"
        },
        {
          text: "Sources",
          value: "sourcesNames"
        }
      ]
    },
  },
}
</script>

<style scoped>
.logo {
  background-position: center;
  width: 50px;
  height: 50px;
  background-color: white;
  display: inline-block;
  overflow: hidden;
  background-size: contain;
  border-radius: 10px;
}
.v-card__title {
  font-size: 1em;
  padding-top: 0;
  padding-bottom: 0;
}
.v-card__actions {
  padding-top: 0;
  padding-left: 1em;
  padding-bottom: 0;
}
>>> .v-data-table__wrapper table > caption {
  font-weight: bold;
  font-size: 1.2em;
}
</style>
