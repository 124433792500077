import {CategoryNames, FeatureNames, LevelNames, SourcesInformation} from "@/assets/js/Enums";

export function extractNames(names, indices) {
    return indices.map(i => names[i]);
}

export default class OpenXrApp {
    constructor({logo, name, url, developers, features, categories, levels, sources, homepage = null, repository = null, description = null, licenses = []}) {
        this.homepage = homepage;
        this.repository = repository;
        this.licenses = licenses;
        this.description = description;
        this.name = name;
        this.url = url;
        this.developers = developers;
        this.features = features.sort();
        this.featuresNames = extractNames(FeatureNames, this.features);
        this.categories = categories.sort();
        this.categoriesNames = extractNames(CategoryNames, this.categories);
        this.levels = levels.sort();
        this.levelsNames = extractNames(LevelNames, this.levels);
        this.sources = sources;
        this.sourcesNames = extractNames(SourcesInformation.map(s => s.name), this.sources);
        this.logo = logo;
    }
}
