export const Features = Object.freeze({
    Pictures360: 0,
    Videos360: 1,
    VirtualTours: 2,
    Interactivity: 3,
    AR: 4,
});
export const FeatureNames = Object.freeze([
    "360° pictures", "360° videos", "Virtual tours", "Interactivity", "Augmented Reality"
]);
export const Categories = Object.freeze({
    Any: 0,
    ArtAndCulture: 1,
    Astronomy: 2,
    Chemistry: 3,
    ComputerScience: 4,
    Geography: 5,
    History: 6,
    LifeSciences: 7,
    Physics: 8,
    Storytelling: 9,
    Tutoring: 10,
});
export const CategoryNames = Object.freeze([
    "Any", "Art and Culture", "Astronomy (space)",  "Chemistry",  "Computer Science", "Geography",
    "History", "Life Sciences (Biology / Medicine)", "Physics", "Story telling", "Tutoring" ]);

export const Levels = Object.freeze({
    Elementary: 0,
    MiddleSchool: 1,
    HighSchool: 2,
    HigherEducation: 3,
    All: () => [Levels.Elementary, Levels.HigherEducation, Levels.HighSchool, Levels.MiddleSchool]
});
export const LevelNames = Object.freeze(["Elementary", "Middle school", "High school", "Higher Education"]);

export const Sources = Object.freeze({
    EduTags: 0,
    Elixier: 1,
    Google: 2,
    Additional: 3
});

export const SourcesInformation = Object.freeze([
    {
        name: "EduTags",
        keywords: ["virtual reality", "vr", "360"],
        url: "https://edutags.de",
        accessed: "25.11.2021"
    },
    {
        name: "Elixier",
        keywords: ["virtual reality", "vr", "360"],
        url: "https://bildungsserver.de/elixier",
        accessed: "25.11.2021"
    },
    {
        name: "Google",
        keywords: ["virtual reality educational app"],
        url: "https://google.de",
        href: "https://www.google.de/search?q=virtual+reality+educational+app",
        accessed: "25.11.2021"
    },
    {
        name: "Additional, manual added examples",
        keywords: [],
        url: null,
        href: null,
        lastUpdate: "01.03.2022"
    }
]);
