import OpenXrApp from "@/assets/js/OpenXrApp.class";
import {Categories, Features, Sources, Levels} from "@/assets/js/Enums";

// eslint-disable-next-line no-unused-vars
const {Any, Tutoring, ComputerScience, Geography, Chemistry, Physics, LifeSciences, ArtAndCulture, History, Storytelling, Astronomy} = Categories;
// eslint-disable-next-line no-unused-vars
const {Pictures360, Videos360, VirtualTours, Interactivity, AR} = Features;
// eslint-disable-next-line no-unused-vars
const {EduTags, Google, Additional, Elixier} = Sources;
// eslint-disable-next-line no-unused-vars
const {Elementary, MiddleSchool, HighSchool, HigherEducation} = Levels;

export default [
    new OpenXrApp({
        name: "EON XR Classic",
        developers: ["EON Reality"],
        logo: "eon_xr.jpg",
        categories: [Tutoring],
        features: [VirtualTours, Interactivity, AR],
        sources: [EduTags],
        levels: Levels.All(),
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Nearpod",
        developers: ["Nearpod"],
        logo: "nearpod.png",
        categories: [Tutoring],
        features: [Pictures360, VirtualTours, AR],
        sources: [EduTags],
        levels: Levels.All(),
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "YouTube VR",
        developers: ["YouTube, LLC"],
        logo: "vr_tube.png",
        categories: [Any],
        features: [Videos360],
        sources: [EduTags],
        levels: Levels.All(),
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Google Earth VR",
        developers: ["Google"],
        logo: "google_earth.png",
        categories: [Geography],
        features: [VirtualTours],
        sources: [Google],
        levels: Levels.All(),
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "MoleculE VR",
        developers: ["Francesca Ghezzo"],
        logo: "moleculE.png",
        categories: [Chemistry],
        features: [Videos360],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Learning MacroMol VR",
        developers: ["EduChem VR"],
        logo: "macromol.png",
        categories: [Chemistry],
        features: [Videos360],
        sources: [EduTags],
        levels: [HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Lightspectrum VR",
        developers: ["Nathan Kong"],
        logo: "lightspec.jpg",
        categories: [Physics],
        features: [Interactivity],
        sources: [EduTags],
        levels: [HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "DIVR Edu",
        developers: ["Nathan Kong"],
        logo: "divr.jpg",
        categories: [Physics, LifeSciences],
        features: [Interactivity],
        sources: [EduTags],
        levels: [HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "RePiX VR",
        developers: ["RWTH Aachen University"],
        logo: "RePiX_VR.png",
        categories: [ComputerScience],
        features: [VirtualTours, Interactivity],
        sources: [Additional],
        levels: [HighSchool, HigherEducation],
        homepage: "https://learntech.rwth-aachen.de/cms/LearnTech/Forschung/LTI-Lab/~kqtlz/RePiX-VR/",
        repository: "https://gitlab.com/learntech-rwth/repix-vr/lvr-rendering-pipeline",
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "VR Classroom",
        developers: ["RWTH Aachen University", "University of Potsdam"],
        logo: "vr_classroom.png",
        categories: [Tutoring],
        features: [VirtualTours, Interactivity],
        sources: [Additional],
        levels: [HigherEducation],
        homepage: "https://www.uni-potsdam.de/de/multimedia/projekte/anwendungen/vr-klassenzimmer",
        repository: "https://gitup.uni-potsdam.de/mm_vr/vr-klassenzimmer",
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Erosion VR",
        developers: ["Nathan Kong"],
        logo: "erosion.png",
        categories: [Geography],
        features: [Videos360],
        sources: [EduTags],
        levels: [MiddleSchool,HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Water Cycle VR",
        developers: ["Victory enterprise"],
        logo: "watercycle.jpg",
        categories: [Geography],
        features: [Videos360, Interactivity],
        sources: [EduTags],
        levels:  [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Kubity Go",
        developers: ["Kubity"],
        logo: "kubity.png",
        categories: [ArtAndCulture],
        features: [VirtualTours, AR],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Roomful Studio",
        developers: ["Roomful Co"],
        logo: "roomful.jpg",
        categories: [ArtAndCulture],
        features: [VirtualTours],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Learning Carbons VR",
        developers: ["EduChem VR"],
        logo: "carbons.png",
        categories: [Chemistry],
        features: [Videos360],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Force Visualization VR",
        developers: ["Nathan Kong"],
        logo: "force_vis.jpg",
        categories: [Physics],
        features: [Interactivity],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Inside the router",
        developers: ["TU Dresden"],
        logo: "inside_the_router.png",
        categories: [ComputerScience],
        features: [VirtualTours, Interactivity],
        sources: [Additional],
        levels: [HighSchool, HigherEducation],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "AnatomYou VR",
        developers: ["Healthware Canarias S.L"],
        logo: "anatomyou.png",
        categories: [LifeSciences],
        features: [VirtualTours],
        sources: [Google],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "New York Times VR",
        developers: ["New York Times"],
        logo: "NYT-VR.png",
        categories: [History, Physics, Geography],
        features: [Videos360],
        sources: [Google],
        levels: [Elementary, MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "ExpeditionsPro VR Tours",
        developers: ["Singleton Technology Ltd"],
        logo: "exp_vr_tours.png",
        categories: [LifeSciences, Astronomy, History, Physics, ArtAndCulture, Geography],
        features: [Pictures360],
        sources: [Elixier],
        levels: [Elementary, MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "VR Showcase",
        developers: ["Youvisit LLC"],
        logo: "showcase.png",
        categories: [Geography],
        features: [Pictures360, Videos360],
        sources: [EduTags],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Drill Dodge VR",
        developers: ["Nathan Kong"],
        logo: "drill_dodge.jpg",
        categories: [Geography],
        features: [Interactivity],
        sources: [EduTags],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Mission:ISS",
        developers: ["Magnopus"],
        logo: "mission_iss.jpg",
        categories: [Astronomy],
        features: [Videos360, Interactivity],
        sources: [Google],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Withing VR",
        developers: ["Within Unlimited. Inc."],
        logo: "within.jpg",
        categories: [Storytelling],
        features: [Videos360],
        sources: [Google],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Athens in VR",
        developers: ["Lithodomos VR"],
        logo: "athen.png",
        categories: [History],
        features: [VirtualTours],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "King Tut VR 2",
        developers: ["EON Reality"],
        logo: "kingtut.png",
        categories: [History],
        features: [Interactivity],
        sources: [EduTags],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "1943 Berlin Blitz",
        developers: ["BBC"],
        logo: "berlin_blitz.jpg",
        categories: [History],
        features: [Videos360],
        sources: [Google],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Timelooper",
        developers: ["TIMELOOPER INC"],
        logo: "timelooper.jpg",
        categories: [History],
        features: [Videos360],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Google Tilt Brush",
        developers: ["Google"],
        logo: "tilt_brush.png",
        categories: [ArtAndCulture],
        features: [Interactivity],
        sources: [Google],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Google Blocks",
        developers: ["Google"],
        logo: "google_blocks.png",
        categories: [ArtAndCulture],
        features: [Interactivity],
        sources: [Google],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "WDR schule digital",
        developers: ["WDR"],
        logo: "wdr.png",
        categories: [History, LifeSciences, ArtAndCulture],
        features: [Videos360],
        sources: [Elixier],
        levels: [Elementary, MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Learning Stereo Chem VR",
        developers: ["EduChem VR"],
        logo: "stereochem.png",
        categories: [Chemistry],
        features: [Videos360],
        sources: [EduTags],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Relative Position VR",
        developers: ["Nathan Kong"],
        logo: "",
        categories: [Physics],
        features: [Interactivity],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "BioExcel",
        developers: ["EduChem VR"],
        logo: "bioexcel.png",
        categories: [LifeSciences],
        features: [Interactivity],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Govie",
        developers: ["Govie"],
        logo: "govie.png",
        categories: [Astronomy, LifeSciences, Geography],
        features: [VirtualTours, AR],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Sesqui VR",
        developers: ["SESQUI Inc."],
        logo: "sesqui.jpg",
        categories: [ArtAndCulture, Geography],
        features: [Videos360],
        sources: [Google],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Ecosphere",
        developers: ["PHORIA"],
        logo: "ecosphere.jpg",
        categories: [Geography],
        features: [Videos360],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "LBS 360-Grad-Fotos",
        developers: ["Landesbildungsserver Baden-Württemberg"],
        logo: "bw.jpg",
        categories: [Geography],
        features: [Pictures360],
        sources: [Elixier],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Earthquake VR",
        developers: ["Nathan Kong"],
        logo: "earthquake.jpg",
        categories: [Geography],
        features: [Videos360],
        sources: [EduTags],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Mars Walk VR",
        developers: ["Lockheed Martin"],
        logo: "marswalk.jpg",
        categories: [Astronomy],
        features: [VirtualTours, Interactivity],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "KSC 360 Expedition",
        developers: ["Kennedy Space Center"],
        logo: "ksc.png",
        categories: [Astronomy],
        features: [Interactivity, AR],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Edge of Home",
        developers: ["Kennedy Space Center"],
        logo: "edge_of_home.png",
        categories: [Astronomy],
        features: [Interactivity, AR],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Paphos Theatre in VR",
        developers: ["Lithodomos VR"],
        logo: "paphos.png",
        categories: [History],
        features: [VirtualTours],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Sigurd & the Dragon VR",
        developers: ["Inspyro Ltd."],
        logo: "sigurd.png",
        categories: [History],
        features: [Videos360],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Schulmuseum Friedrichshafen",
        developers: ["Schulmuseum Friedrichshafen"],
        logo: "friedrichshafen.png",
        categories: [History],
        features: [Videos360],
        sources: [Elixier],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Anne Frank House VR",
        developers: ["Force Field"],
        logo: "anne_frank.jpg",
        categories: [History],
        features: [VirtualTours],
        sources: [Google],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "The Franklin Institute",
        developers: ["The Franklin Institute"],
        logo: "franklin.png",
        categories: [ArtAndCulture],
        features: [VirtualTours],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "The VR Museum of Fine Art",
        developers: ["Finn Sinclair"],
        logo: "fine_art.png",
        categories: [ArtAndCulture],
        features: [VirtualTours],
        sources: [Google],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Bosch VR",
        developers: ["BDH"],
        logo: "bosch.png",
        categories: [ArtAndCulture],
        features: [VirtualTours],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Renwick Gallery WONDER 360",
        developers: ["Smithsonian Institution"],
        logo: "wonder_360.jpg",
        categories: [ArtAndCulture],
        features: [VirtualTours],
        sources: [EduTags],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "The Body VR",
        developers: ["The Body VR LLC"],
        logo: "thebody.jpg",
        categories: [LifeSciences],
        features: [Videos360],
        sources: [Google],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Virtuali-Tee",
        developers: ["Curiscope"],
        logo: "virtuali-tee.png",
        categories: [LifeSciences],
        features: [Videos360, AR],
        sources: [EduTags],
        levels: [MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Unimersiv",
        developers: ["Unimersiv, Inc"],
        logo: "unimersiv.jpg",
        categories: [Astronomy, LifeSciences, Geography],
        features: [VirtualTours],
        sources: [Google],
        levels: [Elementary, MiddleSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Expidition Wilde Welten",
        developers: ["Kubikfoto GmbH"],
        logo: "wildewelten.png",
        categories: [Geography],
        features: [VirtualTours, Interactivity],
        sources: [Elixier],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Cleanopolis",
        developers: ["EDF"],
        logo: "cleanopolis.png",
        categories: [Geography],
        features: [Interactivity],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Titans of space",
        developers: ["DrashVR LLC"],
        logo: "Titans.png",
        categories: [Astronomy],
        features: [VirtualTours],
        sources: [Google],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Baobab VR",
        developers: ["Baobab Studios"],
        logo: "baobab.jpg",
        categories: [Storytelling],
        features: [Videos360],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Peronio Pop-Up Book",
        developers: ["Ovni Studios"],
        logo: "peronio.jpg",
        categories: [Storytelling],
        features: [Videos360],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Dinosaur 4D+",
        developers: ["Octagon Studio Ltd"],
        logo: "dino.png",
        categories: [History],
        features: [Videos360, AR],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Random42 VR",
        developers: ["Random42"],
        logo: "random42.jpg",
        categories: [LifeSciences],
        features: [Videos360],
        sources: [EduTags],
        levels: [MiddleSchool, HighSchool],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "Virry",
        developers: ["Fountain Digital Labs"],
        logo: "virry.jpg",
        categories: [LifeSciences],
        features: [Videos360, Interactivity],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
    new OpenXrApp({
        name: "InMind VR",
        developers: ["NIVAL, INC."],
        logo: "inmind.png",
        categories: [LifeSciences],
        features: [Interactivity],
        sources: [EduTags],
        levels: [Elementary],
        homepage: null, // todo
        repository: null, // todo
        licenses: null, // todo,
        description: null, // todo
    }),
];
